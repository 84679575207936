import Direction from "../DirectionEnum";
import Me from "../media/Me.svg";
import styles from "./MePage.module.css";
import { useHistory } from "react-router-dom";
import CardPage from "../CardPage";
import mia from "../media/forMia.mp3";

export default function MePage() {
    const history = useHistory();

    const toHome = () => {
        history.push({ pathname: "/", state: { direction: Direction.Left } });
    };

    const toVerse = () => {
        history.push({
            pathname: "/three",
            state: { direction: Direction.Right },
        });
    };
    return (
        <CardPage right={toHome} left={toVerse}>
            <audio src={mia} autoPlay />
            <div className={styles.gridcontainer}>
                <img src={Me} alt="" className={styles.cover} />
                <div className={styles.text}>
                    DOESN'T ACTULALLY LOOKE LIKE ME EHH...
                </div>
                <div className={styles.soundOn}>SOUND ON !!!!</div>
            </div>
        </CardPage>
    );
}
