import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import MePage from "./pages/MePage";
import VersePage from "./pages/VersePage";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/two">
                    <MePage />
                </Route>
                <Route exact path="/three">
                    <VersePage />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
