import { useHistory } from "react-router-dom";
import CardPage from "../CardPage";
import Direction from "../DirectionEnum";
import styles from "./VersePage.module.css";
import luke from "../media/luke.mp3";
export default function VersePage() {
    const history = useHistory();
    const toMe = () => {
        history.push({
            pathname: "/two",
            state: { direction: Direction.Left },
        });
    };
    return (
        <CardPage right={toMe}>
            <audio src={luke} autoPlay />
            <div className={styles.gridcontainer}>
                <div className={styles.medium}>
                    AND SUDDENLY THERE WAS WITH THE ANGEL A MULTITUDE OF THE
                    HEAVENLY HOST PRAISING GOD AND SAYING,
                </div>
                <div className={styles.big}>
                    “GLORY TO GOD IN THE HIGHEST, AND ON EARTH PEACE AMONG THOSE
                    WITH WHOM HE IS PLEASED!”
                </div>
                <div className={styles.small}>LUKE 2:13-14 ESV</div>
            </div>
        </CardPage>
    );
}
