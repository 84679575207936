import styles from "./Home.module.css";
import Cover from "../media/Cover.svg";
import { useHistory } from "react-router-dom";
import Direction from "../DirectionEnum";
import CardPage from "../CardPage";
const Home = () => {
    const history = useHistory();
    const next = () => {
        history.push({
            pathname: "/two",
            state: { direction: Direction.Right },
        });
    };

    return (
        <CardPage left={next}>
            <div className={styles.gridcontainer}>
                <img src={Cover} alt="" className={styles.cover} />
                <h1 className={styles.title}>
                    <span className={styles.mia}>M</span>ERRY
                    <br />
                    CHR<span className={styles.mia}>I</span>STM
                    <span className={styles.mia}>A</span>S
                </h1>
                <div className={styles.swipeLeft}>&lt;-- SWIPE LEFT</div>
            </div>
        </CardPage>
    );
};

export default Home;
