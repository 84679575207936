enum Direction {
    Left,
    Right,
}

export default Direction;

export interface directionProp {
    direction?: Direction;
}
